header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* ========== textRef ========== */
.text-ref {
    color: rgb(204, 52, 148);   
}

/* ========== CTA ========== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========== Header Socials ========== */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 10rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ========== Me ========== */
.me {
    background: linear-gradient(var(--color-dark), transparent);
    width: 30rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 15rem);
    margin-top: 4rem;
    border-radius: 25rem;
    overflow: hidden;
    padding: 1.5rem;
}

/* ========== Scroll Down ========== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 10rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ========== Media Queries (Medium Devices) ========== */
@media screen and (max-width: 1024px) {
    header {
        height: 75vh;
        }
}


/* ========== Media Queries (Small Devices) ========== */
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
        }
    
    .header__socials, .scroll__down {
        display: none;
    }
}