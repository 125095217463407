.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

/*
.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
*/
.portfolio__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensure the buttons are at the bottom */
    height: 100%; /* Make sure the parent container takes full height */
    padding: 1rem;
    box-sizing: border-box;

    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    color: var(--color-text);
    text-align: center;
}

.portfolio__item h5 {
    margin: 2rem 0 2rem;
    text-align: center;
}

/* adjust to make the call buttons center at the bottom
.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-left: 5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
*/

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin: 1rem auto 0 auto;  /* Center horizontally with auto margins */
    justify-content: center;  /* Center the buttons within the container */
    padding-bottom: 1rem;  /* Optional: add some padding at the bottom if needed */
}

/* ========== Media Queries (Medium Devices) ========== */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}


/* ========== Media Queries (Small Devices) ========== */
@media screen and (max-width: 600px) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}