@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #140ad3;
    --color-bg-variant: #235eb5;
    --color-primary: rgba(161, 96, 16, 0.8);
    --color-primary-variant: rgba(185, 232, 41, 0.3);
    --color-white: #fff;
    --color-light: rgba(69, 218, 164, 0.8);
    --color-dark: rgba(206, 232, 226, 0.8);
    --color-text: rgb(55, 243, 13);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: radial-gradient(ellipse at bottom, #691562 0%, #15144c 100%);;
    color: var(--color-white);
    line-height: 1.7;
}

/* ========== General Styles ========== */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}


h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 5rem;
}

section > h2, section > h5 {
    text-align: center;
    color: var(--color-light);
}

section > h2 {
    color: var(--color-text);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    /* color: var(--color-primary); */
    color: var(--color-button);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    /* color: var(--color-primary); */
    color: var(--color-button);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    /* color: var(--color-bg); */
    color: var(--color-button)
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ========== Media Queries (Medium Devices) ========== */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ========== Media Queries (Small Devices) ========== */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section {
        margin-top: 2rem;
    }
}